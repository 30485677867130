<template>
  <div class="bg">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-md-5 col-lg-4 mx-auto mt-10 box">
          <div class="login-form mt-3 mt-md-0 mx-auto col-md-12 col-lg-10">
            <div style="text-align: center">
              <img
                style="margin-bottom: 50px; margin-top: 50px"
                src="../img/logoTeros.png"
              />
            </div>

            <div v-if="errorMessage" class="alert alert-danger alert-icon">
              <p>Um erro ocorreu ao tentar conectar na sua conta.</p>
              <p>Erro: {{ errorMessage }}</p>
              <span>Tente novamente ou entre em contato com a Galapos.</span>
            </div>
            <template v-else>
              <div class="alert alert-primary alert-icon">
                Aguarde enquanto terminamos de recuperar as informações da sua
                conta...
              </div>
              <div class="loading-icon-wrapper">
                <v-icon class="animated-loading-icon" dense>mdi-loading</v-icon>
              </div>
            </template>

            <div style="text-align: center">
              <img style="margin-top: 150px" src="../img/logoGalapos.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  computed: {
    resource: function () {
      const id = this.$route.params.id || '';
      return this.apiResource(`v1/authorization/login/oauth/${id}`);
    },
  },
  created: function () {
    if (this.$route.params.id) {
      this.doRequestToken();
    }
  },
  data: function () {
    return {
      errorMessage: null,
    };
  },
  methods: {
    ...mapMutations(["updateAuth"]),
    doRequestToken: async function () {
      try {
        const parsedUrl = new URL(window.location.href.replace("#", "?"));
        const access_token = parsedUrl.searchParams.get("code") || parsedUrl.searchParams.get("access_token");
        const response = await this.resource.save({ access_token });
        
        if (response.error) {
          throw response;
        }

        this.updateAuth(response);
        this.$router.push("/index");
      } catch (error) {
        
        this.errorMessage = this.errorHandler(error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.bg {
  background: url("../img/login.jpg") center;
  background-size: cover;
  height: 100vh;
}

.box {
  background: url("../img/circulos.png") no-repeat;
  background-size: 100%;
  width: 625px;
}

.loading-icon-wrapper {
  display: inline-block;
  text-align: center;
  width: 100%;
}

.animated-loading-icon {
  animation: 1s infinite linear rotationchange;
  color: #fff;
  font-size: 90px;
}

@keyframes rotationchange {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
